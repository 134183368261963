import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || error)
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const localFetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axios.get(url, { ...config });

  return res.data;
};

export const axiosPost = async (url, data = null, config = null) => {
  const res = await axiosInstance.post(url, data, { ...config });
  return res.data;
};

export const axiosPut = async (url, data = null, config = null) => {
  const res = await axiosInstance.put(url, data, { ...config });
  return res.data;
};

export const axiosDelete = async (url, config = null) => {
  const res = await axiosInstance.delete(url, { ...config });
  return res.data;
};

// ----------------------------------------------------------------------

const prefix = HOST_API.includes('vercel') ? '/api' : ''; // This is temp. Remove.

export const endpoints = {
  chat: `${prefix}/chat`,
  kanban: `${prefix}/kanban`,
  calendar: `${prefix}/calendar`,
  auth: {
    me: `${prefix}/auth/me`,
    login: `${prefix}/auth/login`,
    consumeLoginToken: `${prefix}/auth/login-token/consume`,
    register: `${prefix}/auth/register`,
    registerViaInvite: `${prefix}/auth/register-via-invite`,
  },
  mail: {
    list: `${prefix}/mail/list`,
    details: `${prefix}/mail/details`,
    labels: `${prefix}/mail/labels`,
  },
  post: {
    list: `${prefix}/post/list`,
    details: `${prefix}/post/details`,
    latest: `${prefix}/post/latest`,
    search: `${prefix}/post/search`,
  },
  product: {
    list: `${prefix}/product/list`,
    details: `${prefix}/product/details`,
    search: `${prefix}/product/search`,
  },
};
